<template>
  <div>
    <v-row class="d-flex justify-center mx-1 mt-md-4 mt-1">
      <v-col cols="12" sm="10" xl="8">
        <h1 class="text-left mb-6" style="font-family: 'Times New Roman', Times, serif; font-size: 36px">Acerca de Rosy IDE</h1>
        <div class="d-flex">
          <div>
            <p class="text-left">
              Rosy IDE fue desarrollado (como proyecto final de grado) por
              <strong><a class="zelda" href="https://github.com/martins36" target="_blank">Martín Macoritto</a></strong>,
              estudiante de Ingeniería en Informática de la
              <strong><a class="zelda" href="https://www.ucasal.edu.ar/" target="_blank">Universidad Católica de Salta</a></strong>,
              Argentina. Con el objetivo de ayudar y servir a la comunidad.
            </p>
            <p class="text-left">
              Para llevar a cabo este proyecto se utilizó el lenguaje
              <strong><a class="zelda" href="https://www.python.org/" target="_blank">Python</a></strong>, con las librerias
              <strong><a class="zelda" href="http://www.dabeaz.com/ply/" target="_blank">PLY (Python Lex-Yacc)</a></strong> y
              <strong><a class="zelda" href="https://docs.python.org/es/3.8/library/tkinter.html" target="_blank">Tkinter</a></strong>.
            </p>
            <p class="text-left">
              El código fuente de Rosy IDE está publicado con la
              <strong><a class="zelda" href="https://www.gnu.org/licenses/gpl-3.0.txt" target="_blank">GPL (Licencia Pública General) de GNU</a></strong>
              en su versión 3. Otorgando de esta manera a los usuarios las
              <strong><a class="zelda" href="https://www.gnu.org/philosophy/free-sw.html#four-freedoms" target="_blank">cuatro libertades esenciales</a></strong>
              que brinda el uso de un programa de Software Libre.
            </p>
          </div>
          <v-avatar
            v-if="!$vuetify.breakpoint.smAndDown"
            size="160"
            class="ml-4"
            title="Martín Macoritto y Tux"
          >
            <v-img src="../assets/yo.webp" style="opacity: 0.9"></v-img>
          </v-avatar>
        </div>
        <p class="font-italic mt-md-8 mt-4 mx-md-12 mx-8">
          - En el corazón de todo usuario de Software Libre urge la filosofía de que al adquirir un nuevo conocimiento se adquiere consigo la necesidad de compartirlo -
        </p>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  title: 'Acerca de',
}
</script>

<style>
.zelda {
  text-decoration: none;
  color: #2c3e50 !important;
}
.zelda:hover {
  text-decoration: underline;
}
</style>
